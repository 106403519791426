import { db, functions } from "@/firebase";
import router from "@/router";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    latest: [],
    notification: {},
    notifications: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createNotification({ commit }, data) {
    try {
      commit("wait");
      const createNotification = httpsCallable(functions, "createNotification");
      const res = await createNotification(data);

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "notification_created");
      commit("addNotification", res.data.data);
      router.push("/notifications");
    } catch (error) {
      commit("failure", error);
    }
  },
  async getLatestNotifications({ commit }, { estateId, userId }) {
    try {
      commit("wait");
      const q = query(
        collection(db, "notifications"),
        where("estateId", "==", estateId),
        where("senderId", "==", userId),
        orderBy("createdAt", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      const notifications = querySnapshot.docs.map((doc) => {
        const notification = doc.data();
        notification.id = doc.id;
        return notification;
      });

      commit("setLatest", notifications);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getNotification({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "notifications", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const notification = docSnap.data();
        notification.id = docSnap.id;
        commit("setNotification", notification);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getNotifications({ commit }, { estateId, userId }) {
    try {
      commit("wait");
      const q = query(
        collection(db, "notifications"),
        where("estateId", "==", estateId),
        where("user", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      const notifications = querySnapshot.docs.map((doc) => {
        const notification = doc.data();
        notification.id = doc.id;
        return notification;
      });
      commit("setNotifications", notifications);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addNotification(state, notification) {
    state.notifications.push(notification);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setLatest(state, notifications) {
    state.latest = notifications;
    state.waiting = false;
  },
  setNotification(state, notification) {
    state.notification = notification;
    state.waiting = false;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getNotification(state) {
    return state.notification;
  },
};

const notification = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default notification;
