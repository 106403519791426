import { db, functions } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    bulletin: {},
    bulletins: [],
    latest: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async getBulletin({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "bulletins", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const bulletin = docSnap.data();
        bulletin.id = docSnap.id;
        commit("setBulletin", bulletin);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getBulletins({ commit }, estateId) {
    try {
      commit("wait");
      const getBulletins = httpsCallable(functions, "getEstateBulletins");
      const res = await getBulletins({ estateId });

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("setBulletins", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getLatestBulletins({ commit }, estateId) {
    try {
      commit("wait");
      const getLatestBulletins = httpsCallable(
        functions,
        "getLatestEstateBulletins"
      );
      const res = await getLatestBulletins({ estateId });

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("setLatest", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setBulletin(state, bulletin) {
    state.bulletin = bulletin;
    state.waiting = false;
  },
  setBulletins(state, bulletins) {
    state.bulletins = bulletins;
    state.waiting = false;
  },
  setLatest(state, bulletins) {
    state.latest = bulletins;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const bulletin = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default bulletin;
