import { db, functions } from "@/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
const getDefaultState = () => {
  return {
    order: {},
    orders: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createOrder({ commit }, data) {
    try {
      commit("wait");
      const createOrder = httpsCallable(functions, "createOrder");
      const res = await createOrder(data);

      if (!res.data.success) throw "unknown";

      commit("addOrder", res.data.data);
      window.location.href = res.data.data.url;
    } catch (error) {
      commit("failure", error);
    }
  },
  async getOrder({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "orders", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const order = docSnap.data();
        order.id = docSnap.id;
        commit("setOrder", order);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getOrders({ commit }, { estateId, userId }) {
    try {
      commit("wait");
      const q = query(
        collection(db, "orders"),
        where("estateId", "==", estateId),
        where("userId", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      const orders = querySnapshot.docs.map((doc) => {
        const order = doc.data();
        order.id = doc.id;
        return order;
      });
      commit("setOrders", orders);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateOrder({ commit }, data) {
    try {
      commit("wait");
      const updateOrder = httpsCallable(functions, "updateOrder");
      const res = await updateOrder(data);

      if (!res.data.success) throw "unknown";
      commit("setOrder", {});
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addOrder(state, order) {
    state.orders.push(order);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setOrder(state, order) {
    state.order = order;
    state.waiting = false;
  },
  setOrders(state, orders) {
    state.orders = orders;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const order = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default order;
