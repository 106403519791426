import { auth, db, functions } from "@/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    invitations: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async accept({ commit, dispatch }, id) {
    try {
      commit("wait");
      const acceptInvitation = httpsCallable(functions, "acceptInvitation");
      const res = await acceptInvitation({ id });

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "invitation_accepted");
      commit("removeInvitation", id);
      dispatch("authentication/initializeState", auth.currentUser, {
        root: true,
      });
    } catch (error) {
      commit("failure", error);
    }
  },
  async getInvitations({ commit }, userId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "invitations"),
        where("userId", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      const invitations = querySnapshot.docs.map((doc) => {
        const invitation = doc.data();
        invitation.id = doc.id;
        return invitation;
      });
      commit("setInvitations", invitations);
    } catch (error) {
      commit("failure", error);
    }
  },
  async reject({ commit }, id) {
    try {
      commit("wait");
      const rejectInvitation = httpsCallable(functions, "rejectInvitation");
      const res = await rejectInvitation({ id });

      if (!res.data.success) throw "unknown";

      commit("setSuccess", "invitation_rejected");
      commit("removeInvitation", id);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  removeInvitation(state, id) {
    const index = state.invitations.findIndex((i) => i.id == id);

    if (index != -1) {
      state.invitations.splice(index, 1);
    }
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setInvitations(state, invitations) {
    state.invitations = invitations;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const invitation = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default invitation;
