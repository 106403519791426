import { functions, storage } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { getDownloadURL, ref } from "firebase/storage";

const getDefaultState = () => {
  return {
    file: {},
    files: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async getFile({ commit }, path) {
    try {
      const downloadUrl = await getDownloadURL(ref(storage, path));
      commit("setFile", downloadUrl);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getFiles({ commit }, estateId) {
    try {
      commit("wait");
      const getFiles = httpsCallable(functions, "getFiles");
      const res = await getFiles({ estateId });

      if (!res.data.success) throw "unknown";

      commit("setFiles", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setFile(state, file) {
    state.file = file;
    state.waiting = false;
  },
  setFiles(state, files) {
    state.files = files;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const file = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default file;
