<template>
  <div class="relative flex h-full cursor-pointer border-slate-300 sm:border-l">
    <button
      class="flex items-center py-4 px-8"
      @click="toggleProfileMenu"
      @blur="closeProfileMenu"
    >
      <div class="flex items-center overflow-hidden">
        <div
          class="hidden w-full pr-3 text-right text-sm text-slate-700 sm:block"
        >
          <p class="font-bold">{{ user.firstName }} {{ user.lastName }}</p>
          <span>{{ t("navigation.profile") }}</span>
        </div>
        <img
          v-if="user.avatar"
          class="aspect-square h-10 w-10 rounded-full object-cover"
          :src="getAvatar(user)"
        />
        <div
          v-else
          class="aspect-square flex h-10 w-10 items-center justify-center rounded-full bg-slate-300"
        >
          <UserIcon class="h-6 w-6 text-white" />
        </div>
      </div>
    </button>
    <div
      v-if="showProfileMenu"
      class="h-30 absolute right-0 bottom-0 flex w-60 translate-y-full transform flex-col border-t border-slate-300 bg-white shadow-md sm:w-full"
    >
      <div
        v-for="item in items"
        :key="item.label"
        class="cursor-pointer border-b border-slate-300 text-slate-600 hover:bg-slate-100"
        @click="item.command"
      >
        <div class="flex items-center px-4 py-2">
          <component class="h-5 w-5" :is="item.icon" />
          <span class="ml-3 font-semibold">{{
            t(`navigation.${item.label}`)
          }}</span>
        </div>
      </div>
      <div
        class="h-1 w-full"
        :style="{ backgroundColor: company.primary[500] }"
      ></div>
    </div>
  </div>
</template>

<script>
import { ArrowRightOnRectangleIcon, UserIcon } from "@heroicons/vue/24/outline";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getAvatar } from "../../utils/imageUtils";
export default {
  components: { ArrowRightOnRectangleIcon, UserIcon },
  props: {
    company: {
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const hovers = ref([]);

    const showProfileMenu = ref(false);

    const items = reactive([
      {
        command: () => router.push("/profile"),
        icon: UserIcon,
        label: "profile",
      },
      {
        command: () => store.dispatch("authentication/signOut"),
        icon: ArrowRightOnRectangleIcon,
        label: "sign_out",
      },
    ]);

    const toggleProfileMenu = () => {
      setTimeout(() => {
        showProfileMenu.value = !showProfileMenu.value;
      }, 100);
    };

    const closeProfileMenu = () => {
      setTimeout(() => {
        showProfileMenu.value = false;
      }, 100);
    };

    return {
      closeProfileMenu,
      getAvatar: (user) => getAvatar(user),
      hovers,
      items,
      router,
      showProfileMenu,
      toggleProfileMenu,
      t,
      user: computed(() => store.state.authentication.user),
    };
  },
};
</script>
