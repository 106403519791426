import { auth } from "@/firebase";
import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import { isBoardMember, isShareholder } from "../utils/roleUtils";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      authRequired: true,
      breadcrumb: [],
    },
  },
  {
    path: "/bulletins",
    name: "Bulletins",
    component: () => import("../views/Bulletins.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["bulletins"],
    },
  },
  {
    path: "/bulletins/:bulletinId",
    name: "Bulletin",
    component: () => import("../views/Bulletin.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["bulletins", "bulletin"],
    },
  },
  {
    path: "/complete",
    name: "Complete",
    component: () => import("../views/Complete.vue"),
  },
  {
    path: "/documents",
    name: "Documents",
    component: () => import("../views/Documents.vue"),
    meta: {
      authRequired: true,
      shareholderRequired: true,
      breadcrumb: ["documents"],
    },
  },
  {
    path: "/documents/create",
    name: "Document create",
    component: () => import("../views/DocumentCreate.vue"),
    meta: {
      authRequired: true,
      boardMemberRequired: true,
      breadcrumb: ["documents", "create"],
    },
  },
  {
    path: "/documents/:documentId",
    name: "Document",
    component: () => import("../views/Document.vue"),
    meta: {
      authRequired: true,
      shareholderRequired: true,
      breadcrumb: ["documents", "document"],
    },
  },
  {
    path: "/documents/:documentId/edit",
    name: "Document edit",
    component: () => import("../views/DocumentEdit.vue"),
    meta: {
      authRequired: true,
      boardMemberRequired: true,
      breadcrumb: ["documents", "document", "edit"],
    },
  },
  {
    path: "/documents/:documentId/signing/:slug",
    name: "Document",
    component: () => import("../views/DocumentSigning.vue"),
  },
  {
    path: "/files",
    name: "Files",
    component: () => import("../views/Files.vue"),
    meta: {
      authRequired: true,
      shareholderRequired: true,
      breadcrumb: ["files"],
    },
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/Forgot.vue"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["notifications"],
    },
  },
  {
    path: "/notifications/create",
    name: "Notification create",
    component: () => import("../views/NotificationCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["notifications", "create"],
    },
  },
  {
    path: "/notifications/:notificationId",
    name: "Notification",
    component: () => import("../views/Notification.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["notifications", "notification"],
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/Orders.vue"),
    meta: {
      authRequired: true,
      shareholderRequired: true,
      breadcrumb: ["orders"],
    },
  },
  {
    path: "/orders/:orderId",
    name: "Order",
    component: () => import("../views/Order.vue"),
    meta: {
      authRequired: true,
      shareholderRequired: true,
      breadcrumb: ["orders", "order"],
    },
  },
  {
    path: "/payment/cancel",
    name: "Payment cancel",
    component: () => import("../views/PaymentCancel.vue"),
  },
  {
    path: "/payment/success",
    name: "Payment success",
    component: () => import("../views/PaymentSuccess.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile"],
    },
  },
  {
    path: "/profile/edit",
    name: "Profile edit",
    component: () => import("../views/ProfileEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile", "edit"],
    },
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: () => import("../views/Redirect.vue"),
  },
  {
    path: "/shop",
    name: "Shop",
    component: () => import("../views/Shop.vue"),
    meta: {
      authRequired: true,
      shareholderRequired: true,
      breadcrumb: ["shop"],
    },
  },
  {
    path: "/sign-in",
    name: "Sign-in",
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/templates/create",
    name: "Template create",
    component: () => import("../views/DocumentCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["templates", "create"],
    },
  },
  {
    path: "/templates/:templateId",
    name: "Template",
    component: () => import("../views/Document.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["templates", "template"],
    },
  },
  {
    path: "/templates/:templateId/edit",
    name: "Template edit",
    component: () => import("../views/DocumentEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["templates", "template", "edit"],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeResolve(async (to, from, next) => {
  const currentUser = auth.currentUser;
  const idTokenResult = await currentUser?.getIdTokenResult();
  const user = store.state.authentication.user;
  if (to.meta.authRequired && !currentUser) {
    return next("/sign-in");
  } else if (to.meta.authRequired && idTokenResult?.claims.password_reset) {
    return next("/complete");
  } else if (
    to.meta.shareholderRequired &&
    !isShareholder(user) &&
    !isBoardMember(user)
  ) {
    return next("/");
  } else if (to.meta.boardMemberRequired && !isBoardMember(user)) {
    return next("/");
  } else {
    return next();
  }
});

export default router;
