<template>
  <div v-if="!waiting" class="absolute">
    <div
      class="fixed top-0 left-0 z-40 max-h-full w-full border-b-4 bg-white md:z-50 md:block md:h-full md:w-72"
      :style="{ borderColor: company.primary[500] }"
      :class="showNavigation ? 'block' : 'hidden'"
    >
      <div class="pt-20">
        <div class="relative">
          <img
            class="aspect-rectangle w-full rounded-md object-cover"
            :src="getEstateImage(estate)"
            alt="Estate image"
          />
          <div
            v-if="!estate.image"
            class="absolute top-0 left-0 h-full w-full opacity-25"
            :style="{
              backgroundColor: company.primary[500],
            }"
          ></div>
        </div>
        <div
          class="hidden h-1 md:block"
          :style="{ backgroundColor: company.primary[500] }"
        ></div>
        <navigation-items :company="company" />
        <div class="block p-4 md:hidden">
          <label>{{ t("labels.your_estates") }}</label>
          <form-select
            class="my-2"
            id="estate-select"
            :invalid="false"
            :optionLabel="['name']"
            :options="estates"
            v-model="currentEstate"
          />
        </div>
      </div>
    </div>
    <div
      class="fixed top-0 left-0 z-50 flex h-20 w-full items-center justify-between bg-white shadow-md"
    >
      <div class="flex items-center gap-4 py-4 px-6">
        <button
          class="focus:outline-none flex h-8 w-8 items-center justify-center rounded-md bg-white focus:ring md:hidden"
          :style="{
            '--tw-ring-color': company.primary[300],
          }"
          @click="toggleNavigation"
          @blur="closeNavigation"
        >
          <Bars3Icon class="w-full text-slate-500" />
        </button>
        <img
          class="object-contain"
          :class="!company || (company && !company.logo) ? 'h-12' : 'h-8'"
          :src="getLogo(company)"
        />
      </div>
      <div class="flex h-full">
        <button
          class="relative hidden cursor-pointer border-l border-slate-300 md:block"
          @click="toggleEstates"
          @blur="closeEstates"
        >
          <div
            class="flex h-full items-center gap-2 border-b-4 py-4 px-9"
            :style="{ borderColor: company.primary[500] }"
          >
            <label
              class="cursor-pointer border-b border-slate-300 pb-1 font-bold"
            >
              {{ estate && estate.name ? estate.name : null }}
            </label>
            <ArrowPathIcon class="h-7 w-7" />
          </div>
          <div
            v-if="showEstates"
            class="h-30 absolute left-0 bottom-0 flex w-full translate-y-full transform flex-col border-t border-slate-300 bg-white shadow-md"
          >
            <div
              v-for="estate in estates"
              :key="estate.id"
              class="cursor-pointer border-b border-slate-300 text-slate-600 hover:bg-slate-100"
              @click="changeEstate(estate.id)"
            >
              <div class="flex items-center px-4 py-2">
                <span class="ml-3 font-semibold">{{ estate.name }}</span>
              </div>
            </div>
            <div
              class="h-1 w-full"
              :style="{ backgroundColor: company.primary[500] }"
            ></div>
          </div>
        </button>
        <navigation-profile :company="company" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  Bars3Icon,
} from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { getEstateImage, getLogo } from "../../utils/imageUtils";
import FormSelect from "../form/FormSelect.vue";
import NavigationItems from "./NavigationItems.vue";
import NavigationProfile from "./NavigationProfile.vue";
export default {
  components: {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    Bars3Icon,
    FormSelect,
    NavigationItems,
    NavigationProfile,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const hovers = ref([]);

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const estates = computed(() => store.state.estate.estates);
    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.estate.waiting) return true;
      return false;
    });

    const showNavigation = ref(false);

    const toggleNavigation = () => {
      setTimeout(() => {
        showNavigation.value = !showNavigation.value;
      }, 100);
    };

    const currentEstate = ref(null);
    const showEstates = ref(false);

    const toggleEstates = () => {
      setTimeout(() => {
        showEstates.value = !showEstates.value;
      }, 100);
    };

    const closeEstates = () => {
      setTimeout(() => {
        showEstates.value = false;
      }, 100);
    };

    const changeEstate = (id) => store.dispatch("estate/changeEstate", id);

    watch(
      () => currentEstate.value,
      (id) => {
        if (estate.value.id != id) changeEstate(id);
      }
    );

    onMounted(() => {
      currentEstate.value = estate.value.id;
    });

    return {
      changeEstate,
      closeEstates,
      company,
      currentEstate,
      estate,
      estates,
      getEstateImage,
      getLogo,
      hovers,
      showEstates,
      showNavigation,
      t,
      toggleEstates,
      toggleNavigation,
      waiting,
    };
  },
};
</script>
