import { db } from "@/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
const getDefaultState = () => {
  return {
    product: {},
    products: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async getProduct({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "products", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const product = docSnap.data();
        product.id = docSnap.id;
        commit("setProduct", product);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getProducts({ commit }, estateId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "products"),
        where("estateIds", "array-contains", estateId),
        where("available", "==", true)
      );
      const querySnapshot = await getDocs(q);
      const products = querySnapshot.docs.map((doc) => {
        const product = doc.data();
        product.id = doc.id;
        return product;
      });
      commit("setProducts", products);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setProduct(state, product) {
    state.product = product;
    state.waiting = false;
  },
  setProducts(state, products) {
    state.products = products;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const product = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default product;
