import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    company: {},
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async getCompany({ commit }, estateId) {
    try {
      commit("wait");
      const getCompany = httpsCallable(functions, "getCompanyTheme");
      const res = await getCompany({ estateId });

      if (!res.data.success) throw "unknown";

      commit("setCompany", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCompany(state, company) {
    state.company = company;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getLicenceValidity(state) {
    return new Date() <= new Date(state.company.licenceValidity);
  },
};

const company = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default company;
