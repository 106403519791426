import { auth, db } from "@/firebase";
import {
  collection,
  doc,
  documentId,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const getDefaultState = () => {
  return {
    estate: {},
    estates: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async changeEstate({ commit, dispatch }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(docRef, { currentEstate: id });
      dispatch("company/getCompany", id, { root: true });
      commit("setEstate", id);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getEstates({ commit }, { estateIds, estateId }) {
    try {
      commit("wait");
      const q = query(
        collection(db, "estates"),
        where(documentId(), "in", estateIds)
      );
      const querySnapshot = await getDocs(q);
      const estates = querySnapshot.docs.map((doc) => {
        const estate = doc.data();
        estate.id = doc.id;
        return estate;
      });

      commit("setEstates", estates);
      commit("setEstate", estateId);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setEstate(state, estateId) {
    const estate = state.estates.find((e) => e.id == estateId);
    state.estate = estate;
    state.waiting = false;
  },
  setEstates(state, estates) {
    state.estates = estates;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const estate = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default estate;
