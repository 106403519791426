const getDefaultState = () => {
  return {
    status: {},
    user: {},
    users: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  setUsers(state, users) {
    state.users = users;
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const user = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default user;
